import { navigate } from "@reach/router"
import axios from "axios"
import $ from "jquery/dist/jquery.slim"

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}
const setUser = (user, language) => {
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

  if (language === "en") {
    navigate(`/enApp/profile`)
  } else {
    navigate(`/app/profile`)
  }
}

export const handlePasswordChange = ({
  password,
  newPassword,
  reNewPassword,
}) => {
  const user = getUser()
  const body = {
    username: user.name,
    password: password,
  }
  $(".splash-screen").addClass("show")
  $(".password-mismatch").removeClass("show-password")
  $(".change-password-error").removeClass("show-password")
  if (newPassword !== reNewPassword) {
    $(".splash-screen").removeClass("show")
    $(".password-mismatch").addClass("show-password")
  } else {
    axios
      .post("https://taxidevadmin.com/wp-json/jwt-auth/v1/token", body)
      .then(response => {
        if (response.status === 200) {
          const authStr = "Bearer ".concat(response.data.token)
          return axios
            .post(
              `https://taxidevadmin.com/wp-json/wp/v2/users/${user.id}`,
              { password: newPassword },
              { headers: { Authorization: authStr } }
            )
            .then(response =>{
              $(".splash-screen").removeClass("show")
              $(".success-msg").addClass("show-password");
              setTimeout(() => {
                navigate("/app/profile")
              }, 2000)
            })
            .catch(error => {
              alert(error.status)
            })
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          $(".splash-screen").removeClass("show")
          $(".change-password-error").addClass("show-password")
          $(".password-mismatch").removeClass("show-password")
        } else {
        }
      })
  }
}
export async function handleLogin ({ username, password }, language) {
  const body = {
    username: username,
    password: password,
  }
  const userInfo = {}
  // Logging in loading screen
  $(".splash-screen").addClass("show")
  $(".password-error").removeClass("show-password")
  //fix for the names of the groups
  const groupNames = [];
  groupNames["arir"] = "aðrir"
  groupNames["leyfishafar"] = "Leyfishafar"
  groupNames["afleysingar"] = "Afleysingar"
  groupNames["administrator"] = "Leyfishafar"
  groupNames["vefstjri"] = "Leyfishafar"
  axios
    .post("https://taxidevadmin.com/wp-json/jwt-auth/v1/token", body)
    .then(response => {
      if (response.status === 200) {

        //("3")
        //(response)
        userInfo.username = response.data.user_display_name
        userInfo.name = response.data.user_nicename
        userInfo.email = response.data.user_email
        //("3")
        const authStr = "Bearer ".concat(response.data.token)
        return axios
          .get("https://taxidevadmin.com/wp-json/wp/v2/users/me?context=edit", {headers: { Authorization: authStr }})
          .then(response =>{
            //(response)
            $(".splash-screen").removeClass("show")
            userInfo.id = response.data.id
            userInfo.role = groupNames[response.data.roles[0]]
            return setUser(
              {
                username: userInfo.username,
                name: userInfo.name,
                email: userInfo.email,
                role: userInfo.role,
                id: userInfo.id,
              },
              language
            )
      })
    }
  })
  .catch(error => {
    $(".password-error").addClass("show-password")
    $(".splash-screen").removeClass("show")
    return false
  })
}
export const isLoggedIn = () => {
  const user = getUser()
  return !!user.username
}

export const logout = language => {
  setUser({}, language)
}
